import styled, { css } from 'styled-components'

import { getDisplayedCSSBackgroundImage, getPaddingValue } from './utils'
import { hideBlockAt, marginBreakpoints } from '../../../../utils/commonAttrs'
import { fromBreakpoint } from '../../../../utils/style/breakpoint'
import type { ImageData } from '../../../../types/Image'

type StripeBlockProps = {
  backgroundColor?: string
  backgroundImage?: string
  overlap?: number
  paddingBottom?: 'normal' | 'narrow' | 'none'
  paddingTop?: 'normal' | 'narrow' | 'none'
  backgroundGradient?: string
  marginBottom?: string
  hiddenAt?: string
}

export const StripeBlock = styled.section<StripeBlockProps>`
  content-visibility: auto;
  position: relative;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  ${({ backgroundGradient }) =>
    getDisplayedCSSBackgroundImage({ backgroundGradient })}
  ${({ marginBottom }) => marginBreakpoints({ marginBottom }, 0)}
    ${({ hiddenAt }) => hideBlockAt({ hiddenAt })}
`

type StripeContentProps = {
  hasImage?: boolean
  overlap?: number
  paddingBottom: 'normal' | 'narrow' | 'none'
  paddingTop: 'normal' | 'narrow' | 'none'
}

export const OverlapWrapper = styled.div<StripeContentProps>`
padding: ${({ paddingBottom, paddingTop }) =>
  `${getPaddingValue(paddingTop)}px 0 ${getPaddingValue(paddingBottom)}px`};

  /*
    If the stripe contains an image, it will by default overlap the rest of the
    content if we don't set its z-index to a value higher than 0. We avoid
    doing this by default, as relative positioning as a default breaks some of
    the blocks' layout.
  */
  ${({ hasImage }) =>
    hasImage &&
    css`
      position: relative;
      z-index: 1;
    `}

  ${({ overlap }) =>
    overlap &&
    css`
      position: relative;
      transform: translate(0, ${overlap ? `-${overlap}px` : 0});
    `}

  ${fromBreakpoint('md')} {
    padding: ${({ paddingBottom, paddingTop }) =>
      `${getPaddingValue(paddingTop, 768)}px 0 ${getPaddingValue(
        paddingBottom,
        768
      )}px`};
  }

  ${fromBreakpoint('xl')} {
    padding: ${({ paddingBottom, paddingTop }) =>
      `${getPaddingValue(paddingTop, 1200)}px 0 ${getPaddingValue(
        paddingBottom,
        1200
      )}px`};
  }
`

type PictureWrapperProps = {
  align?: 'left' | 'right' | 'cover'
  image: ImageData
}

export const PictureWrapper = styled.div<PictureWrapperProps>`
  width: 100%;
  height: 100%;
  ${props =>
    props.align === 'left' || props.align === 'right'
      ? `${props.align}: 0;`
      : ``}
  ${props => (props.align === 'cover' ? `position: absolute; top: 0;` : ``)}
      
  ${fromBreakpoint('md')} {
    position: absolute;
    ${props =>
      props.align === 'left' || props.align === 'right' ? `width: 48%;` : ``}
    top: 0;
    z-index: 0;
    overflow: hidden;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`
